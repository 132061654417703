<template>
  <v-form fast-fail @submit.prevent class="bg-background">
    <v-select
      v-model="raising"
      :items="raisings"
      label="Fundraising Round*"
      :rules="[rules.required]"
    />
    <v-select
      v-model="offer"
      :items="offers"
      label="Offer to Investor*"
      :rules="[rules.required]"
    />
    <CurrencyInput v-model="rawAmount" :isReadonly="disableAmount" />
  </v-form>
</template>

<script setup>
import { ref, watchEffect, defineEmits } from "vue";
import { useCommonStore } from "@/store/common";
import { useCompanyStore } from "@/store/company";
import { useOnePagerStore } from "@/store/onePager";
import { storeToRefs } from "pinia";
import CurrencyInput from "../CurrencyInput.vue";

const emit = defineEmits(["disableNextButton"]);

const commonStore = useCommonStore();
const companyStore = useCompanyStore();
const onePagerStore = useOnePagerStore();
const { setInvestmentDetails } = companyStore;
const { rules } = storeToRefs(commonStore);
const { raisings, offers } = storeToRefs(onePagerStore);

let offer = ref(offers.value[0]);
let raising = ref(raisings.value[0]);
let rawAmount = ref(0);
let disableAmount = ref(false);

watchEffect(() => {
  if (raising.value === "Seeking Feedback") {
    rawAmount.value = 0.0;
    disableAmount.value = true;
  } else {
    disableAmount.value = false;
  }

  if (
    isNaN(rawAmount.value) ||
    rawAmount.value < 0 ||
    (rawAmount.value === 0 && raising.value !== "Seeking Feedback")
  ) {
    emit("disableNextButton", true);
  } else {
    setInvestmentDetails({
      investmentTerm: raising.value,
      offer: offer.value,
      amountRaising: rawAmount.value,
    });

    emit("disableNextButton", false);
  }
});
</script>

<style scoped>
.v-form {
  width: 100%;
  margin-top: 3%;
}
</style>
