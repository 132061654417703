<template>
  <NavBar :editorView="true" />
  <TwoColLayout
    :leftCols="isMobile.pitch ? 12 : 8"
    :rightCols="isMobile.pitch ? 0 : 4"
    :leftLayout="PitchForm"
    :rightLayout="AideSection"
    :withNav="true"
  />
</template>

<script setup>
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import NavBar from "@/components/NavBar.vue";
import TwoColLayout from "@/components/TwoColLayout.vue";
import PitchForm from "@/components/Forms/PitchForm.vue";
import AideSection from "@/components/AideSection.vue";

const commonStore = useCommonStore();
const { isMobile } = storeToRefs(commonStore);
</script>
