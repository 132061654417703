<template>
  <v-row
    class="drop-zone justify-content-center align-center"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="getFiles"
    @change="getSelectedFiles"
    :class="{ 'active-dropzone': active }"
  >
    <p>Drag and drop PDF file</p>
    <p>or</p>
    <label for="pitchDeckFile">Upload PDF file</label>
    <input
      type="file"
      id="pitchDeckFile"
      class="pitchDeckFile"
      accept=".pdf"
      @click="$event.target.value = ''"
    />
    <p>PDF file: {{ filename }}</p>
  </v-row>

  <LoadingDialog
    :modelValue="openLoadingDialog"
    message="Saving Pitch Deck..."
  />

  <ErrorDialog
    v-model="openErrorDialog"
    :title="errorTitle"
    :message="errorMsg"
    @close-dialog="closeErrorDialog"
  />

  <SuccessDialog
    v-model="openSuccessDialog"
    title="Uploaded and saved!"
    message="Pitch deck has been successfully uploaded and saved."
    @close-dialog="openSuccessDialog = false"
  />
</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { usePitchStore } from "@/store/pitch";
import { useUserStore } from "@/store/user";
import {
  ref as storageRef,
  getStorage,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase/init";
import { doc, setDoc } from "firebase/firestore";
import pdfvuer from "pdfvuer";
import LoadingDialog from "@/components/Dialogs/LoadingDialog.vue";
import ErrorDialog from "../../Dialogs/ErrorDialog.vue";
import SuccessDialog from "../../Dialogs/SuccessDialog.vue";

const emit = defineEmits(["disableNextButton"]);

const userStore = useUserStore();
const pitchStore = usePitchStore();

const { setPitchDeckFile } = pitchStore;

const { currentUser } = storeToRefs(userStore);
const { pitch } = storeToRefs(pitchStore);

let openLoadingDialog = ref(false);
let openErrorDialog = ref(false);
let openSuccessDialog = ref(false);
let errorTitle = ref("");
let errorMsg = ref("");
const active = ref(false);
const file = ref("");
const newFile = ref("");
const filename = ref("");
const storageFolder = "pitchdecks/";
const pdfdata = ref(null);
const numPages = ref(0);
const maxPages = 15;

const toggleActive = () => {
  active.value = !active.value;
};
const getFiles = (event) => {
  newFile.value = event.dataTransfer.files[0];

  toggleActive();
  validateFile();
};
const getSelectedFiles = () => {
  newFile.value = document.querySelector(".pitchDeckFile").files[0];

  validateFile();
};
const validateFile = async () => {
  if (newFile.value.type !== "application/pdf") {
    errorTitle.value = "Wrong type of file!";
    errorMsg.value =
      "Oops! It looks like you uploaded the wrong type of file. Currently, Pitch Deck only accepts PDF files.";
    openErrorDialog.value = true;
  } else {
    pdfdata.value = pdfvuer.createLoadingTask(
      URL.createObjectURL(newFile.value)
    );

    await pdfdata.value.then((pdf) => {
      numPages.value = pdf.numPages;
    });

    if (numPages.value > maxPages) {
      errorTitle.value = "Too many slides!";
      errorMsg.value =
        "Oops! It looks like your pitch deck has more than 15 slides. For optimal effectiveness, pitch decks should be limited to 15 slides. Please adjust and upload your deck within this limit.";
      openErrorDialog.value = true;
    } else {
      file.value = newFile.value;
      await getFirebaseData();
    }
  }
};
const getFirebaseData = async () => {
  openLoadingDialog.value = true;
  try {
    const fbStorage = getStorage();
    const fbStorageRef = storageRef(fbStorage, storageFolder + file.value.name);

    const snapshot = await uploadBytes(fbStorageRef, file.value);
    console.log(snapshot);
    const downloadURL = await getDownloadURL(storageRef(fbStorageRef));

    await setDoc(doc(db, "pitchdecks", currentUser.value.uid), {
      downloadURL: downloadURL,
      filename: file.value.name,
    });
    setPitchDeckFile({
      filename: file.value.name,
      url: downloadURL,
    });

    openSuccessDialog.value = true;
  } catch (e) {
    console.log(e);
  }

  openLoadingDialog.value = false;
};
const closeErrorDialog = () => {
  errorTitle.value = "";
  errorMsg.value = "";
  newFile.value = "";
  pdfdata.value = null;
  numPages.value = 0;
  openErrorDialog.value = false;
};

//lifecycle hooks
onMounted(() => {
  file.value = pitch.value.pitchDeck;
});

watchEffect(() => {
  filename.value =
    file.value !== ""
      ? file.value.name
        ? file.value.name
        : file.value.filename !== ""
        ? file.value.filename
        : "No PDF file uploaded"
      : "No PDF file uploaded";

  emit("disableNextButton", pitch.value.pitchDeck.url === "");
});
</script>

<style scoped>
.drop-zone {
  margin-top: 3%;
  height: 43vh;
  width: 100%;
  flex-direction: column;
  border: 2px dashed #4c6fbf;

  label {
    padding: 1% 2%;
    background-color: #4c6fbf;
    color: white !important;
    margin: 2%;
  }

  input {
    display: none;
  }
}
.active-dropzone {
  color: white;
  border-color: white;
  background-color: #4c6fbf;

  label {
    background-color: white;
    color: #4c6fbf !important;
    margin: 2%;
  }
}
</style>
