import { defineStore } from "pinia";
import { ref } from "vue";

export const useOnePagerStore = defineStore(
  "OnePagerStore",
  () => {
    const stages = ref(["Ideation", "Validation", "Launch", "Growth"]);
    const raisings = ref([
      "Angel / Pre-seed",
      "Seed",
      "Series A",
      "Seeking Feedback",
    ]);
    const offers = ref([
      "Equity - Share Purchase Agreement",
      "Equity - Convertible Note",
      "Equity - SAFE",
      "Debt Financing",
      "Revenue-based Financing",
    ]);
    const onePager = ref([
      {
        label: "Name of Startup",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Startup Overview",
        subtitle: "(up to 90 words)",
        type: "textarea",
        model: null,
        isReadonly: true,
      },
      {
        label: "Startup Stage",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Fundraising Round",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Offer to Investor",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "No. of Employees",
        type: "textfield",
        model: null,
        isReadonly: false,
      },
      {
        label: "Market Size",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Amount Raising (PHP)",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Use of Fund",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Challenge",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Solution",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Market",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Milestone",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Revenue Model",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Competitive Advantage",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Exit",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Founding Team",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
    ]);

    const setOnePager = (payload) => {
      onePager.value = payload;
    };
    const prepopulate = (payload) => {
      const { name, description, stage, raising, offer, amount } = payload;
      console.log(payload);

      onePager.value[0].model = name;
      onePager.value[1].model = description;
      onePager.value[2].model = stage;
      onePager.value[3].model = raising;
      onePager.value[4].model = offer;
      onePager.value[7].model = amount;
    };
    const clearOnePager = () => {
      onePager.value.forEach((element) => (element.model = null));
    };

    return {
      stages,
      raisings,
      offers,
      onePager,
      setOnePager,
      prepopulate,
      clearOnePager,
    };
  },
  { persist: true }
);
